<template>
  <div
    class="c--sim_item-row sim-item-row"
    :class="[
      theme,
      { last: isLast },
      { 'flex-wrap': isSub },
      { 'promoted tw-promo-prices-bg': isPromotedPrice },
      { inverted: hasColorsInverted },
    ]"
    :style="{ color: needsTextColor ? invertedTextColor : 'auto' }"
  >
    <template v-if="icon && !isPromotedPrice">
      <template v-if="iconIsSvg">
        <template v-if="isTest">
          <span v-html="require(`~/assets/images/svg/${icon}.svg`)" class="list-icon sim-item-row-left-icon"></span>
        </template>
        <template v-else>
          <span v-html="require(`~/assets/images/svg/${icon}.svg?raw`)" class="list-icon sim-item-row-left-icon"></span>
        </template>
      </template>
      <template v-else>
        <span :class="[icon, 'list-icon sim-item-row-left-icon', 'row-item-icon-padding']" />
      </template>
    </template>

    <SharedSalePrice
      v-if="isPromotedPrice"
      :discountOrFormattedPrice="discountPercentage"
      :displayIcon="true"
      :isDiscount="true"
      :salePriceEndDateIso="salePriceEndDateIso"
      :tooltipTargetId="tooltipTargetId"
    />
    <p v-else :data-testid="`${keyText}-row`" class="key sim-item-row-left-key">{{ keyText }}</p>

    <p
      :data-testid="`${keyText}-value`"
      class="value sim-item-row-right-value ltr:!tw-ml-auto rtl:!tw-mr-auto"
      :class="[
        { 'd-inline-flex': rightIcon && rightIcon.length > 0 },
        { price: isPrice },
        { 'has-promotion': priceHasPromotion },
        { promoted: isPromotedPrice },
        loader ? 'relative sim-item-row-right-value-loader' : 'overflow-hidden',
      ]"
    >
      <template v-if="loader">
        <client-only>
          <preloader :value="loader" :theme="theme" class="relative" />
        </client-only>
      </template>
      <template v-else>
        <template v-if="isRemainingUsage">
          <template v-if="valueCheck(value) === 'string'">
            {{ value }}
          </template>
          <template v-else>
            <RemainingUsage :data="value" :remaining-type="remainingUsageType" />
          </template>
        </template>
        <template v-else>
          {{ value }}
        </template>
        <template v-if="isTest">
          <span
            class="d-inline-flex"
            :class="{ 'rtl-transform-rotate-y': $dir() === 'rtl' }"
            v-html="require(`~/assets/images/svg/${rightIcon}.svg`)"
            v-if="rightIcon && rightIcon.length > 0"
          ></span>
        </template>
        <template v-else>
          <span
            class="d-inline-flex"
            :class="{ 'rtl-transform-rotate-y': $dir() === 'rtl' }"
            v-html="require(`~/assets/images/svg/${rightIcon}.svg?raw`)"
            v-if="rightIcon && rightIcon.length > 0"
          ></span>
        </template>
      </template>
    </p>
    <div class="sim-item-row-sub" v-if="isSub">
      <template v-if="isTest">
        <span v-html="require(`~/assets/images/svg/${subIcon}.svg`)" class="list-icon"></span>
      </template>
      <template v-else>
        <span v-html="require(`~/assets/images/svg/${subIcon}.svg?raw`)" class="list-icon"></span>
      </template>
      <p class="sub-text">
        {{ subText }}
      </p>
    </div>
  </div>
</template>

<script>
  import Preloader from '~/components/inner-preloader.vue'
  import RemainingUsage from '~/components/sim/item-remaining-usage.vue'
  import SharedSalePrice from '~/components/shared/sale-price.vue'

  export default {
    components: {
      Preloader,
      RemainingUsage,
      SharedSalePrice,
    },
    props: {
      keyText: {
        type: String,
        required: false,
        default: null,
      },
      value: {
        required: true,
      },
      iconIsSvg: {
        type: Boolean,
        required: false,
        default: false,
      },
      icon: {
        type: String,
        required: false,
      },
      rightIcon: {
        type: String,
        required: false,
        default: '',
      },
      theme: {
        type: String,
        required: false,
        default: 'light',
      },
      isLast: {
        type: Boolean,
        required: false,
        default: false,
      },
      isSub: {
        type: Boolean,
        required: false,
        default: false,
      },
      subIcon: {
        type: String,
        required: false,
        default: '',
      },
      subText: {
        type: String,
        required: false,
        default: '',
      },
      loader: {
        type: Boolean,
        required: false,
        default: false,
      },
      isRemainingUsage: {
        type: Boolean,
        required: false,
        default: false,
      },
      remainingUsageType: {
        type: String,
        required: false,
        default: '', // data, voice, text
      },
      isTest: {
        type: Boolean,
        required: false,
        default: false,
      },
      isPrice: {
        type: Boolean,
        required: false,
        default: false,
      },
      priceHasPromotion: {
        type: Boolean,
        required: false,
        default: false,
      },
      isPromotedPrice: {
        type: Boolean,
        required: false,
        default: false,
      },
      discountPercentage: {
        type: [String, Number],
        required: false,
      },
      tooltipTargetId: {
        type: String,
        required: false,
      },
      salePriceEndDateIso: {
        type: String,
        required: false,
      },
      hasColorsInverted: {
        type: Boolean,
        required: false,
        default: false,
      },
      invertedTextColor: {
        type: String,
        required: false,
        default: '',
      },
    },
    computed: {
      needsTextColor() {
        return this.invertedTextColor && this.hasColorsInverted
      },
    },

    methods: {
      valueCheck() {
        return typeof this.value
      },
    },
  }
</script>

<style lang="scss" scoped>
  .row-item-icon-padding {
    padding: 3px;
  }
  .sim-item-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid transparent;
    padding: 18px 20px 19px;
    width: 100%;

    &-sub {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
    }

    &-left-key,
    &-right-value {
      max-width: calc(50% - 18px);
      white-space: nowrap;
      text-overflow: ellipsis;
      &.price {
        max-width: none;
      }

      &.sim-item-row-right-value-loader {
        padding-right: 17px;
      }
    }

    &.promoted {
      .sim-item-row-left-key {
        font-size: 0.875rem;
        line-height: 1rem;
      }
    }

    span {
      &.list-icon {
        display: inline-flex;
        font-size: 16px;
        margin-right: 10px;

        [dir='rtl'] & {
          margin-right: unset;
          margin-left: 10px;
        }
      }
    }

    &.iccid p.value {
      max-width: 180px;
    }

    &.coverage p.value {
      max-width: 140px;
    }

    &.button {
      display: flex;
      justify-content: center;
      padding-top: 14px;
      padding-bottom: 15px;
    }

    &.button p.value {
      border: 1px solid currentColor;
      width: 100%;
      max-width: 128px;
      padding: 9px 10px;
      font-size: 0.625rem;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 1.5px;
      text-align: center;
      border-radius: 3px;
    }

    p {
      margin: 0;

      &.key {
        font-size: 0.6875rem;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 1;
      }

      &.value {
        font-size: 0.9375rem;
        font-weight: 600;
        letter-spacing: -0.1px;
        line-height: 1.2;
        text-align: end;
        &.price {
          font-size: 1.25rem;
          &.has-promotion {
            font-size: 1.125rem;
            font-weight: 400;
            text-decoration: line-through;
          }
          &.promoted {
            font-size: 1.375rem;
          }
        }
      }

      &.sub-text {
        font-size: 0.8125rem;
        letter-spacing: 0;
        line-height: 1.384615;
      }
    }

    &.light {
      color: #ffffff;
      border-color: #ffffff;
      border-bottom-color: rgba(#eeeeee, 0.1);
      &.inverted {
        color: currentColor;
        background: #fff;
      }
    }

    &.dark {
      color: #4a4a4a;
      border-color: #4a4a4a;
      border-bottom-color: rgba(#343434, 0.1);
      &.inverted {
        color: #ffffff;
        background: #4a4a4a;
      }
    }

    &.light,
    &.dark {
      &.promoted {
        border-top: 1px solid #ffffff;
        color: #ffffff;
      }
      &.inverted {
        margin: 0.3em;
        padding: 1em;
        border-radius: 4px;
      }
    }

    &.last {
      border-bottom: none;
    }

    & ::v-deep .inner-preloader-wrapper {
      width: 22px;
      height: 22px;
      transform: scale(0.5);
      transform-origin: center;
    }
  }
</style>
