<template>
  <div
    class="c--freemium-country-selector freemium-country tw-w-full tw-rounded tw-border tw-border-solid tw-border-primary tw-p-[16px] tw-mb-6"
  >
    <div
      class="freemium-country-title tw-flex tw-items-center tw-text-3 tw-font-semibold tw-leading-3 tw-tracking-wide tw-uppercase tw-cursor-pointer tw-select-none"
      @click="isExpanded = !isExpanded"
    >
      <i
        data-testid="freemium-country-icon"
        :class="['airalo-icon-play3 tw-mr-1.25', { 'tw-rotate-90': isExpanded }]"
      />
      <span>{{ $t('components.freemium.view-valid-destinations') }}</span>
    </div>
    <div
      v-if="isExpanded"
      class="tw-mt-4 tw-pt-4 tw-text-14 tw-leading-[20px] tw-border-0 tw-border-t tw-border-solid tw-border-zinc-150 tw-relative"
    >
      <div class="freemium-country-list-search-container">
        <span class="icon search tw-absolute tw-top-[29px] tw-left-[12px]">
          <i class="airalo-icon-search" />
        </span>
        <input
          v-model="searchValue"
          :placeholder="$t('components.freemium.country-name')"
          class="tw-w-full freemium-country-search"
          data-testid="freemium-country-search-bar"
          type="text"
        />
        <span
          v-if="searchValue.length > 0"
          @click.prevent="searchValue = ''"
          class="icon close-icon tw-cursor-pointer tw-absolute tw-top-[27px] tw-right-[12px]"
        >
          <i class="airalo-icon-close-round-filled" />
        </span>
      </div>
      <div class="freemium-country-list-countries tw-mt-2 tw-max-h-[200px] tw-overflow-y-auto">
        <div
          v-for="country in searchedCountryList"
          class="tw-py-4 tw-border-0 tw-border-b tw-border-solid tw-border-zinc-150 tw-flex tw-items-center tw-justify-between tw-cursor-pointer"
          @click="redirectToFreemiumPackage(country)"
        >
          <div>
            <img :src="country.image.url" :alt="country.title" width="37px" height="24px" class="tw-mr-2" />
            <span class="tw-text-15 tw-font-semibold">{{ country.title }}</span>
          </div>
          <i class="airalo-icon-arrow-right tw-text-primary tw-text-3" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { removeAccents } from '@/plugins/remove-accents'

  export default {
    data() {
      return {
        isExpanded: false,
        searchValue: '',
        countriesList: [],
      }
    },
    emits: ['onChooseCountry'],
    async mounted() {
      try {
        const response = await this.$axios.get('/freemium/countries')
        if (response?.data?.countries) {
          this.countriesList = response.data.countries
        }
      } catch (error) {
        this.$sentry.addBreadcrumb({
          type: 'error',
          category: 'Freemium',
          message: `API /freemium/countries:`,
        })
      }
    },

    computed: {
      searchedCountryList() {
        if (this.searchValue) {
          return this.countriesList.filter((country) => {
            return removeAccents(country.title.toLowerCase()).includes(removeAccents(this.searchValue.toLowerCase()))
          })
        } else {
          return this.countriesList
        }
      },
    },

    methods: {
      redirectToFreemiumPackage(country) {
        this.$emit('onChooseCountry')
        this.$router.push(this.localePath(`/${country.slug}-esim/${country.package_slug}`))
      },
    },
  }
</script>
<style lang="scss">
  .freemium-country {
    &-search {
      padding-left: 30px !important;
      background: #ddd !important;
    }
  }
</style>
