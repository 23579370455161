var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    attrs: {
      "dir": _vm.usage.type === 'text' ? _vm.$dir() : 'auto'
    }
  }, [_vm.usage.type === 'text' ? [_c('span', [_vm._v(_vm._s(_vm.usage.value))])] : _vm.usage.type === 'remaining-voice' ? [_c('span', [_vm._v(_vm._s(_vm.$t('global.minutes', {
    amount: _vm.usage.value
  })))])] : _vm.usage.type === 'remaining-text' ? [_c('span', [_vm._v(_vm._s(_vm.$t('global.sms', {
    amount: _vm.usage.value
  })))])] : _vm.usage.type === 'remaining-data' ? [_c('span', [_vm._v("\n      " + _vm._s(_vm.parseRemainingData(_vm.usage.value)) + "\n    ")])] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }