var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--sim_promotion-badge tw-inline-flex tw-justify-center tw-items-center tw-promo-prices-bg tw-rounded-lg tw-border tw-border-white tw-border-solid tw-shadow-xs tw-min-w-[84px] tw-h-[24px] tw-px-2 tw-pt-[4.5px] tw-pb-[5.5px]",
    attrs: {
      "data-testid": "promotion-badge"
    }
  }, [_c('span', {
    staticClass: "tw-text-14 tw-font-semibold tw-text-white tw-mb-0"
  }, [_vm._v("\n    " + _vm._s(_vm.$t('components.promo.prices.percentage-off', {
    percentage: _vm.percentage
  })) + "\n  ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }