var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.allPropsReady ? _c('client-only', [_c('b-popover', {
    attrs: {
      "boundary-padding": 0,
      "target": _vm.tooltipTargetId,
      "show": _vm.showTooltip,
      "offset": 0,
      "container": "body",
      "custom-class": "sale-price-tooltip",
      "modal-class": "promotion-end-date-modal",
      "placement": "top",
      "triggers": "manual",
      "boundary": "viewport"
    },
    on: {
      "update:show": function ($event) {
        _vm.showTooltip = $event;
      }
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column justify-content-start align-items-start"
  }, [_c('p', {
    staticClass: "tw-text-21 tw-font-semibold tw-w-full tw-text-center"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('components.promo.prices.sale-price-title')) + "\n      ")]), _vm._v(" "), _c('p', {
    staticClass: "overlay-tooltip-text !tw-text-14 tw-w-full tw-text-center"
  }, [_vm._v("\n        " + _vm._s(_vm.tooltipMessage) + "\n      ")])])])], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }