var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--inner-preloader inner-preloader-wrapper"
  }, [_c('div', {
    staticClass: "inner-preloader lds-roller",
    class: [_vm.theme],
    attrs: {
      "data-testid": "esim-info-loader"
    }
  }, [_c('div'), _vm._v(" "), _c('div'), _vm._v(" "), _c('div'), _vm._v(" "), _c('div'), _vm._v(" "), _c('div'), _vm._v(" "), _c('div'), _vm._v(" "), _c('div'), _vm._v(" "), _c('div')])]);

}
var staticRenderFns = []

export { render, staticRenderFns }