<template>
  <div class="c--shared_sale-price-discount tw-flex tw-flex-row tw-gap-2.5 tw-items-center tw-justify-items-start">
    <div v-if="displayIcon" class="airalo-icon-sale tw-text-30" data-testid="sale-price-icon"></div>
    <div data-testid="sale-price-label" class="tw-text-14/[18px] tw-font-semibold">
      <div
        :class="{
          'tw-mb-1': !isDiscount,
          'tw-underline tw-cursor-pointer': isBeforePurchasePage,
        }"
        :id="tooltipTargetId"
        @mouseover="onMouseOver"
        @mouseleave="onMouseLeave"
        @click.prevent.stop=""
      >
        {{ $t('global.sale-price') }}
      </div>
      <div :class="{ 'tw-text-5.5/[26px]': !isDiscount }">{{ promotionValue }}</div>
    </div>

    <SharedSalePriceTooltip
      v-if="isBeforePurchasePage"
      :salePriceEndDateFormatted="salePriceEndDateFormatted"
      :showTooltip="showTooltip"
      :tooltipTargetId="tooltipTargetId"
    />
  </div>
</template>

<script>
  import SharedSalePriceTooltip from '~/components/shared/sale-price-tooltip'

  export default {
    props: {
      discountOrFormattedPrice: { type: [String, Number], required: true },
      displayIcon: { type: Boolean, required: false, default: false },
      isDiscount: { type: Boolean, required: false, default: false },
      salePriceEndDateIso: { type: String, required: false },
      tooltipTargetId: { type: String, required: true },
    },

    components: {
      SharedSalePriceTooltip,
    },

    data() {
      return {
        showTooltip: false,
      }
    },

    computed: {
      salePriceEndDateFormatted() {
        return process.client && !!this.salePriceEndDateIso
          ? `${this.$dateFormat(this.salePriceEndDateIso, 'Do MMMM yyyy', false)} (GMT+0)`
          : null
      },
      promotionValue() {
        return this.isDiscount
          ? `(${this.$t('components.promo.prices.percentage-off', { percentage: this.discountOrFormattedPrice })})`
          : this.discountOrFormattedPrice
      },
      isBeforePurchasePage() {
        return !!this.salePriceEndDateIso
      },
    },

    methods: {
      onMouseOver() {
        this.showTooltip = true
      },
      onMouseLeave() {
        this.showTooltip = false
      },
    },
  }
</script>
