var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--shared_sale-price-discount tw-flex tw-flex-row tw-gap-2.5 tw-items-center tw-justify-items-start"
  }, [_vm.displayIcon ? _c('div', {
    staticClass: "airalo-icon-sale tw-text-30",
    attrs: {
      "data-testid": "sale-price-icon"
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "tw-text-14/[18px] tw-font-semibold",
    attrs: {
      "data-testid": "sale-price-label"
    }
  }, [_c('div', {
    class: {
      'tw-mb-1': !_vm.isDiscount,
      'tw-underline tw-cursor-pointer': _vm.isBeforePurchasePage
    },
    attrs: {
      "id": _vm.tooltipTargetId
    },
    on: {
      "mouseover": _vm.onMouseOver,
      "mouseleave": _vm.onMouseLeave,
      "click": function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      }
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('global.sale-price')) + "\n    ")]), _vm._v(" "), _c('div', {
    class: {
      'tw-text-5.5/[26px]': !_vm.isDiscount
    }
  }, [_vm._v(_vm._s(_vm.promotionValue))])]), _vm._v(" "), _vm.isBeforePurchasePage ? _c('SharedSalePriceTooltip', {
    attrs: {
      "salePriceEndDateFormatted": _vm.salePriceEndDateFormatted,
      "showTooltip": _vm.showTooltip,
      "tooltipTargetId": _vm.tooltipTargetId
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }