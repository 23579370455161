var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--freemium-modal"
  }, [_c('general-modal', {
    attrs: {
      "modal-id": "freemium-modal",
      "hasCloseButton": true,
      "modalView": _vm.viewModal,
      "height": "unset"
    },
    on: {
      "onPressClose": _vm.onClose
    }
  }, [_c('div', {
    staticClass: "tw-px-4"
  }, [_c('nuxt-img', {
    staticClass: "w-auto tw-block tw-mx-auto tw-mb-6",
    attrs: {
      "src": "/assets/images/freemium/freemium-banner-large.svg",
      "alt": "Freemium",
      "loading": "lazy"
    }
  }), _vm._v(" "), _c('h2', {
    staticClass: "tw-text-7 tw-font-semibold tw-text-primary tw-my-6 tw-mx-auto tw-block tw-text-center"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('components.freemium.how-to-get-free-esim')) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "tw-mt-6 tw-pt-6 tw-pb-8 tw-px-4 tw-text-14 tw-leading-[20px] tw-border-0 tw-border-t tw-border-solid tw-border-zinc-150",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('components.freemium.how-to-get-description'))
    }
  }), _vm._v(" "), _c('FreemiumCountrySelector', {
    on: {
      "onChooseCountry": _vm.onClose
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }