<template>
  <client-only v-if="allPropsReady">
    <b-popover
      :boundary-padding="0"
      :target="tooltipTargetId"
      :show.sync="showTooltip"
      :offset="0"
      container="body"
      custom-class="sale-price-tooltip"
      modal-class="promotion-end-date-modal"
      placement="top"
      triggers="manual"
      boundary="viewport"
    >
      <div class="d-flex flex-column justify-content-start align-items-start">
        <p class="tw-text-21 tw-font-semibold tw-w-full tw-text-center">
          {{ $t('components.promo.prices.sale-price-title') }}
        </p>
        <p class="overlay-tooltip-text !tw-text-14 tw-w-full tw-text-center">
          {{ tooltipMessage }}
        </p>
      </div>
    </b-popover>
  </client-only>
</template>

<script>
  export default {
    props: {
      salePriceEndDateFormatted: { type: String, required: false },
      showTooltip: { type: Boolean, required: false },
      tooltipTargetId: { type: String, required: false },
    },

    computed: {
      allPropsReady() {
        return !!this.showTooltip && !!this.tooltipTargetId
      },

      tooltipMessage() {
        return !!this.salePriceEndDateFormatted
          ? this.$t('components.promo.prices.promotion-ends', { promotionEndDate: this.salePriceEndDateFormatted })
          : this.$t('components.promo.prices.sale-ended-description')
      },
    },
  }
</script>
