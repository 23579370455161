<template>
  <span :dir="usage.type === 'text' ? $dir() : 'auto'">
    <template v-if="usage.type === 'text'">
      <span>{{ usage.value }}</span>
    </template>
    <template v-else-if="usage.type === 'remaining-voice'">
      <span>{{
        $t('global.minutes', {
          amount: usage.value,
        })
      }}</span>
    </template>
    <template v-else-if="usage.type === 'remaining-text'">
      <span>{{
        $t('global.sms', {
          amount: usage.value,
        })
      }}</span>
    </template>
    <template v-else-if="usage.type === 'remaining-data'">
      <span>
        {{ parseRemainingData(usage.value) }}
      </span>
    </template>
  </span>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        required: true,
      },
      remainingType: {
        type: String, // data, voice, text
        required: false,
        default: 'data',
      },
    },

    computed: {
      usage() {
        const status = this.data.status.toLowerCase() // active, not_active, expired, finished
        const simHasUnlimitedData = this.data.is_unlimited === true
        const showingData = this.remainingType === 'data'
        const simHasUnlimitedVoice = this.data.is_unlimited_voice === true
        const showingVoice = this.remainingType === 'voice'
        const simHasUnlimitedText = this.data.is_unlimited_text === true
        const showingText = this.remainingType === 'text'
        const showingUnlimitedEntitlement =
          (showingData && simHasUnlimitedData) ||
          (showingVoice && simHasUnlimitedVoice) ||
          (showingText && simHasUnlimitedText)

        let returnObject = {
          type: 'text', // text, remaining-data, remaining-text
          value: null,
        }

        if (status === 'expired') {
          returnObject.value = this.$t('global.expired')
        } else if (status === 'finished') {
          returnObject.value = this.$t('global.finished')
        } else {
          if (showingUnlimitedEntitlement) {
            returnObject.value = this.$t('global.unlimited')
          } else if (status === 'unknown') {
            if (this.remainingType === 'voice') {
              returnObject.type = 'remaining-voice'
              returnObject.value = this.data.total_voice
            } else if (this.remainingType === 'text') {
              returnObject.type = 'remaining-text'
              returnObject.value = this.data.total_text
            } else {
              returnObject.type = 'remaining-data'
              returnObject.value = this.data.total
            }
          } else {
            if (this.remainingType === 'voice') {
              returnObject.type = 'remaining-voice'
              returnObject.value = this.data.remaining_voice
            } else if (this.remainingType === 'text') {
              returnObject.type = 'remaining-text'
              returnObject.value = this.data.remaining_text
            } else {
              returnObject.type = 'remaining-data'
              returnObject.value = this.data.remaining
            }
          }
        }

        return returnObject
      },
    },

    methods: {
      parseRemainingData(data) {
        return data < 1024
          ? data + ' MB'
          : parseFloat(data / 1024)
              .toFixed(2)
              .replace('.00', '') + ' GB'
      },
    },
  }
</script>
