<template>
  <div
    class="c--sim_promotion-badge tw-inline-flex tw-justify-center tw-items-center tw-promo-prices-bg tw-rounded-lg tw-border tw-border-white tw-border-solid tw-shadow-xs tw-min-w-[84px] tw-h-[24px] tw-px-2 tw-pt-[4.5px] tw-pb-[5.5px]"
    data-testid="promotion-badge"
  >
    <span class="tw-text-14 tw-font-semibold tw-text-white tw-mb-0">
      {{
        $t('components.promo.prices.percentage-off', {
          percentage: percentage,
        })
      }}
    </span>
  </div>
</template>

<script>
  export default {
    props: {
      percentage: {
        required: true,
      },
    },
  }
</script>
