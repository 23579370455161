var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--sim_item-row sim-item-row",
    class: [_vm.theme, {
      last: _vm.isLast
    }, {
      'flex-wrap': _vm.isSub
    }, {
      'promoted tw-promo-prices-bg': _vm.isPromotedPrice
    }, {
      inverted: _vm.hasColorsInverted
    }],
    style: {
      color: _vm.needsTextColor ? _vm.invertedTextColor : 'auto'
    }
  }, [_vm.icon && !_vm.isPromotedPrice ? [_vm.iconIsSvg ? [_vm.isTest ? [_c('span', {
    staticClass: "list-icon sim-item-row-left-icon",
    domProps: {
      "innerHTML": _vm._s(require(`~/assets/images/svg/${_vm.icon}.svg`))
    }
  })] : [_c('span', {
    staticClass: "list-icon sim-item-row-left-icon",
    domProps: {
      "innerHTML": _vm._s(require(`~/assets/images/svg/${_vm.icon}.svg?raw`))
    }
  })]] : [_c('span', {
    class: [_vm.icon, 'list-icon sim-item-row-left-icon', 'row-item-icon-padding']
  })]] : _vm._e(), _vm._v(" "), _vm.isPromotedPrice ? _c('SharedSalePrice', {
    attrs: {
      "discountOrFormattedPrice": _vm.discountPercentage,
      "displayIcon": true,
      "isDiscount": true,
      "salePriceEndDateIso": _vm.salePriceEndDateIso,
      "tooltipTargetId": _vm.tooltipTargetId
    }
  }) : _c('p', {
    staticClass: "key sim-item-row-left-key",
    attrs: {
      "data-testid": `${_vm.keyText}-row`
    }
  }, [_vm._v(_vm._s(_vm.keyText))]), _vm._v(" "), _c('p', {
    staticClass: "value sim-item-row-right-value ltr:!tw-ml-auto rtl:!tw-mr-auto",
    class: [{
      'd-inline-flex': _vm.rightIcon && _vm.rightIcon.length > 0
    }, {
      price: _vm.isPrice
    }, {
      'has-promotion': _vm.priceHasPromotion
    }, {
      promoted: _vm.isPromotedPrice
    }, _vm.loader ? 'relative sim-item-row-right-value-loader' : 'overflow-hidden'],
    attrs: {
      "data-testid": `${_vm.keyText}-value`
    }
  }, [_vm.loader ? [_c('client-only', [_c('preloader', {
    staticClass: "relative",
    attrs: {
      "value": _vm.loader,
      "theme": _vm.theme
    }
  })], 1)] : [_vm.isRemainingUsage ? [_vm.valueCheck(_vm.value) === 'string' ? [_vm._v("\n          " + _vm._s(_vm.value) + "\n        ")] : [_c('RemainingUsage', {
    attrs: {
      "data": _vm.value,
      "remaining-type": _vm.remainingUsageType
    }
  })]] : [_vm._v("\n        " + _vm._s(_vm.value) + "\n      ")], _vm._v(" "), _vm.isTest ? [_vm.rightIcon && _vm.rightIcon.length > 0 ? _c('span', {
    staticClass: "d-inline-flex",
    class: {
      'rtl-transform-rotate-y': _vm.$dir() === 'rtl'
    },
    domProps: {
      "innerHTML": _vm._s(require(`~/assets/images/svg/${_vm.rightIcon}.svg`))
    }
  }) : _vm._e()] : [_vm.rightIcon && _vm.rightIcon.length > 0 ? _c('span', {
    staticClass: "d-inline-flex",
    class: {
      'rtl-transform-rotate-y': _vm.$dir() === 'rtl'
    },
    domProps: {
      "innerHTML": _vm._s(require(`~/assets/images/svg/${_vm.rightIcon}.svg?raw`))
    }
  }) : _vm._e()]]], 2), _vm._v(" "), _vm.isSub ? _c('div', {
    staticClass: "sim-item-row-sub"
  }, [_vm.isTest ? [_c('span', {
    staticClass: "list-icon",
    domProps: {
      "innerHTML": _vm._s(require(`~/assets/images/svg/${_vm.subIcon}.svg`))
    }
  })] : [_c('span', {
    staticClass: "list-icon",
    domProps: {
      "innerHTML": _vm._s(require(`~/assets/images/svg/${_vm.subIcon}.svg?raw`))
    }
  })], _vm._v(" "), _c('p', {
    staticClass: "sub-text"
  }, [_vm._v("\n      " + _vm._s(_vm.subText) + "\n    ")])], 2) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }