<template>
  <div class="c--inner-preloader inner-preloader-wrapper">
    <div class="inner-preloader lds-roller" :class="[theme]" data-testid="esim-info-loader">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'preloader',

    props: {
      theme: {
        type: String,
        required: false,
        default: 'dark',
      },
    },
  }
</script>

<style lang="scss" scoped>
  .inner-preloader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    &.lds-roller {
      &.dark {
        div {
          &:after {
            background: #4a4a4a;
          }
        }
      }

      &.secondary {
        div {
          &:after {
            background: #8a8a8a;
          }
        }
      }

      &.light {
        div {
          &:after {
            background: #ffffff;
          }
        }
      }
    }
  }
</style>
