<template>
  <div class="c--freemium-modal">
    <general-modal
      modal-id="freemium-modal"
      :hasCloseButton="true"
      :modalView="viewModal"
      height="unset"
      @onPressClose="onClose"
    >
      <div class="tw-px-4">
        <nuxt-img
          src="/assets/images/freemium/freemium-banner-large.svg"
          alt="Freemium"
          class="w-auto tw-block tw-mx-auto tw-mb-6"
          loading="lazy"
        />
        <h2 class="tw-text-7 tw-font-semibold tw-text-primary tw-my-6 tw-mx-auto tw-block tw-text-center">
          {{ $t('components.freemium.how-to-get-free-esim') }}
        </h2>
        <div
          class="tw-mt-6 tw-pt-6 tw-pb-8 tw-px-4 tw-text-14 tw-leading-[20px] tw-border-0 tw-border-t tw-border-solid tw-border-zinc-150"
          v-html="$t('components.freemium.how-to-get-description')"
        ></div>
        <FreemiumCountrySelector @onChooseCountry="onClose" />
      </div>
    </general-modal>
  </div>
</template>

<script>
  import GeneralModal from '~/components/modals/general-modal'
  import FreemiumCountrySelector from '~/components/freemium/country-selector'

  export default {
    components: {
      GeneralModal,
      FreemiumCountrySelector,
    },

    props: {
      viewModal: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    methods: {
      async onClose() {
        this.$emit('onClose')
      },
    },
  }
</script>
