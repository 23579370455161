// Example: https://codepen.io/marcelo-ribeiro/pen/OJmVOyW

const accentsMap = new Map([
  ['A', 'Á|À|Ã|Â|Ä|Å'],
  ['a', 'á|à|ã|â|ä|å'],
  ['E', 'É|È|Ê|Ë'],
  ['e', 'é|è|ê|ë'],
  ['I', 'Í|Ì|Î|Ï'],
  ['i', 'í|ì|î|ï'],
  ['O', 'Ó|Ò|Ô|Õ|Ö'],
  ['o', 'ó|ò|ô|õ|ö'],
  ['U', 'Ú|Ù|Û|Ü'],
  ['u', 'ú|ù|û|ü'],
  ['C', 'Ç'],
  ['c', 'ç'],
  ['N', 'Ñ'],
  ['n', 'ñ']
])

const reducer = (acc, [key]) =>
  acc.replace(new RegExp(accentsMap.get(key), 'g'), key)

export const removeAccents = (text) => [...accentsMap].reduce(reducer, text)
